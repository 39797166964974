import Input from "../../common/Input";
import CustomBtn from "../../common/CustomBtn";

import Banner from "../../../assets/images/banner.png";
import GoogleLogo from "../../../assets/icons/google-logo.png";
import {
  ContactUsSvg,
  Images,
  LoginCircleSvg,
  UnboardLogoSvg,
} from "../../../assets";
import { auth, doc, firestoreDb, getDoc } from "../../../libraries/firebase";
import {
  signInWithPopup,
  GoogleAuthProvider,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Alert, Stack } from "@mui/material";
import { BoldText, MedText, RegText, SemiBoldText } from "../../../styles";
import { Colors } from "../../../themes";
import { validateEmail } from "../../../helper/validation";
import Loading from "../../common/Loading";
import { CircularProgress, Snackbar } from "@mui/material";
import { trackEvent } from "../../../utils/functions";

const SigninScreen = () => {
  const provider = new GoogleAuthProvider();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [user, userLoading] = useAuthState(auth);
  const [loading, setLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [sentToEmail, setSentToEmail] = useState("");

  useEffect(() => {
    if (user) {
      navigate("/");
      return;
    }

    captureEmailSignIn();
  }, [user]);

  const googleSignIn = async () => {
    try {
      trackEvent("Sign in - Google");
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      if (user) {
        const isNewUser = await getDoc(doc(firestoreDb, "Users", user.uid));
        if (isNewUser.data()) {
          if (localStorage.getItem("openViwerInvitationAfterSignIn")) {
            navigate(
              `/viewer-invitation/${JSON.parse(
                localStorage.getItem("openViwerInvitationAfterSignIn")
              )}`
            );
            return;
          }
          navigate("/");
          return;
        }

        navigate("/signup");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const emailSignIn = async (event) => {
    trackEvent("Sign in - Send email link", {
      email,
    });

    event.preventDefault();
    setLoading(true);

    const isEmailValid = validateEmail(email, setError);
    if (!isEmailValid) {
      setLoading(false);

      return;
    }

    try {
      await sendSignInLinkToEmail(auth, email, {
        url: process.env.REACT_APP_WEBSITE_URL + "signin/",
        handleCodeInApp: true,
      });

      localStorage.setItem("email", email);
      setLoading(false);
      setIsEmailSent(true);
      setEmail("");
      setSentToEmail(email);
    } catch (e) {
      console.error(e);
      setError("Something went wrong, please try again");
      setLoading(false);
    }
  };

  const captureEmailSignIn = async () => {
    const email = localStorage.getItem("email");

    if (isSignInWithEmailLink(auth, window.location.href) && email) {
      try {
        await signInWithEmailLink(auth, email, window.location.href);
        if (localStorage.getItem("openViwerInvitationAfterSignIn")) {
          navigate(
            `/viewer-invitation/${JSON.parse(
              localStorage.getItem("openViwerInvitationAfterSignIn")
            )}`
          );
        }
      } catch (e) {
        console.error("Error: Sign in with email link failed!", e);
      }

      localStorage.removeItem("email");
    }
  };

  const handleContactUs = () => {
    trackEvent("Sign in - Contact Us");
    window.location.href = "https://www.ellenox.com/contact";
  };

  if (userLoading) {
    return <Loading />;
  }
  return (
    <div className="h-[100dvh] relative overflow-hidden">
      <LoginCircleSvg className="absolute bottom-0 top-0 z-[-10] hidden md:block" />
      <img
        src={Banner}
        alt="banner"
        className="absolute bottom-0 w-[66.5%] hidden md:block"
      />
      <img
        src={Images.bannerSmaller}
        alt="banner"
        className="absolute bottom-0 right-0 left-0 block md:hidden z-[-10]"
      />
      <div className="text-white   md:max-w-[83rem] mx-auto ">
        <Stack
          sx={styles.navbar}
          className="h-[72px] md:h-[60px] md:mt-[29px] mb-6 md:mb-0 shadow-md md:shadow-none"
        >
          <Stack
            direction="row"
            alignItems="center"
            gap="9px"
            className="text-newPrimary md:text-white "
          >
            <UnboardLogoSvg
              fill="currentColor"
              className="h-[19px] md:h-[26px]"
            />
          </Stack>
          <div className="flex" onClick={handleContactUs}>
            <SemiBoldText
              component={"a"}
              href={`${process.env.REACT_APP_ELLENOX_WEBSITE}`}
              variant="body2"
              target="_blank"
              display={{ xs: "none", md: "block" }}
              className="text-newPrimary"
            >
              Contact us
            </SemiBoldText>
            <div className="block md:hidden text-newPrimary mr-4">
              <ContactUsSvg fill="currentColor" />
            </div>
          </div>
        </Stack>

        <div className="md:max-w-[75rem] mx-auto flex flex-col md:flex-row  md:justify-between  md:mt-[71px]">
          <div className="md:w-1/2 text-black md:text-white text-center md:text-left z-[50]">
            <BoldText sx={styles.heading}>
              Experience the Power
              <br className="hidden md:block" /> of Collaboration with AI
            </BoldText>
            <div className="text-white font-normal hidden md:flex">
              <RegText variant="body1">
                We believe that AI has the power to transform the
                <br /> world, but we also recognize that AI is only as good as
                <br /> the data that feeds it.
              </RegText>
            </div>
          </div>
          <div className="w-full px-5 md:px-0 md:w-[440px] mx-auto md:mx-0 mt-[77px] md:mt-20">
            <SemiBoldText className="text-grey7 text-center md:!text-xl !mb-6 md:!mb-[42px] font-semibold">
              Start Collaborating
            </SemiBoldText>
            <form className="" onSubmit={(e) => e.preventDefault()}>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onPaste={(e) => e.preventDefault()}
                placeholder={"Enter Email"}
              />
              {error && <div className="text-sm text-red mt-2">{error}</div>}
              <div className="flex flex-col mt-20 md:mt-10">
                <CustomBtn
                  type="submit"
                  onClick={emailSignIn}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress
                      sx={{ color: Colors.newPrimary }}
                      size={30}
                    />
                  ) : (
                    <MedText variant="body2">Send sign in link</MedText>
                  )}
                </CustomBtn>
              </div>
            </form>
            <div className="flex flex-col">
              <button
                className="bg-white py-3 mt-3  flex justify-center items-center gap-4 border-[1px] border-grey4/40 rounded-md"
                onClick={googleSignIn}
              >
                <img src={GoogleLogo} alt="google" className="w-6 h-6" />
                <MedText sx={{ color: Colors.primaryColor }} variant="body2">
                  Continue with Google
                </MedText>
              </button>
              <div className="text-grey7  flex items-center gap-6 justify-center mt-4 md:mt-[56px]">
                <a
                  href={"/privacy-policy"}
                  onClick={() => trackEvent("Sign in - Privacy")}
                >
                  <RegText className="!text-sm">Privacy Policy</RegText>
                </a>
                <a
                  href={"/terms-of-use"}
                  onClick={() => trackEvent("Sign in - TOS")}
                >
                  <RegText className="!text-sm">Terms of Use</RegText>
                </a>
              </div>
            </div>
            <Snackbar
              open={isEmailSent}
              autoHideDuration={6000}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              onClose={() => setIsEmailSent(false)}
            >
              <Alert
                variant="filled"
                severity="success"
                sx={{ backgroundColor: "white", color: "black" }}
              >
                {`Email sent to: ${sentToEmail}`}
              </Alert>
            </Snackbar>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SigninScreen;
const styles = {
  navbar: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    px: 0,
  },
  heading: {
    fontSize: { xs: "24px", md: "48px" },
    lineHeight: { xs: "30px", md: "56px" },
    mb: "15px",
  },
};
