import React, { useState } from "react";
import { Eye, Images } from "../../../assets";
import AppDrawer from "../../common/AppDrawer";
import zIndex from "@mui/material/styles/zIndex";
import Chat from "./chat";

const Hero = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <div className=" text-center relative flex flex-col items-center mt-[107px] lg:mt-0 lg:justify-center w-full lg:w-1/2 h-[100svh] lg:h-screen">
      <p className="font-helvetica font-bold text-[22px] lg:text-2xl mb-10">
        UnBoard
      </p>
      <h1 className="font-bold font-grotesque text-[52px] leading-[52px] sm:text-[64px] sm:leading-[64px] lg:text-[84px] lg:leading-[76px]">
        Work with AI,
        <br /> collaboratively.
      </h1>
      <p className="font-helvetica text-lg lg:text-2xl mt-6 lg:mt-[28px] lg:leading-[28.8px]">
        All the AI(s) you need for power work,
        <br />
        with your team, in one subscription.
      </p>
      <div className="mt-[193px] lg:mt-[152px] w-[308px] flex flex-col gap-3 relative">
        <img
          src={Images.LandingPageHeart}
          className="w-[263px] lg:w-[244px] h-[319px] lg:h-[296px] object-contain -translate-x-1/2 absolute left-1/2 -bottom-[56px]"
          alt="background"
        />
        <input
          className="relative backdrop-blur-md placeholder:text-[#686868] bg-white/10 font-helvetica py-[11px] pl-6 rounded-full border border-[#E3E3E3] w-[308px]"
          placeholder="Enter email"
        />
        <button className="relative bg-[#3C60F0] w-[308px] text-white py-[11px] rounded-full font-bold">
          Early Bird Access
        </button>
      </div>
      <button
        onClick={() => setOpenDrawer(true)}
        className="lg:hidden relative mt-[96px] flex items-center justify-center gap-3 font-bold text-[#3C60F0]"
      >
        <Eye className="w-[21.5px] h-[14px]" />
        <p>Try Live Demo</p>
      </button>
      <AppDrawer
        appDrawerProps={{
          open: openDrawer,
          anchor: "right",
          closeModal: () => setOpenDrawer(false),
          drawerWidth: "100%",
        }}
        style={{
          height: "100svh",
          overflow: "auto",
          backgroundColor: "#e3e3e3",
        }}
        type={"swipeable"}
      >
        <Chat />
      </AppDrawer>
    </div>
  );
};

export default Hero;
