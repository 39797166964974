import { ReactComponent as AvatarSvg } from "./svg/Avatar.svg";
import { ReactComponent as EditSvg } from "./svg/edit.svg";
import { ReactComponent as EditThreeSvg } from "./svg/edit-3.svg";
import { ReactComponent as BackArrowSvg } from "./svg/backArrow.svg";
import { ReactComponent as ProfileSvg } from "./svg/TwoUserProfile.svg";
import { ReactComponent as LogoSvg } from "./svg/navIcon.svg";
import { ReactComponent as DividerSvg } from "./svg/divider.svg";
import { ReactComponent as ArrowRightSvg } from "./svg/arrow-Right.svg";
import { ReactComponent as AddChatSvg } from "./svg/addChat.svg";
import { ReactComponent as TopNavLineSvg } from "./svg/topNavLine.svg";
import { ReactComponent as UserInvitationSvg } from "./svg/userInvitation.svg";
import { ReactComponent as MenuSvg } from "./svg/menu.svg";
import { ReactComponent as DotMenuSvg } from "./svg/dot-menu.svg";
import { ReactComponent as ContactUsSvg } from "./svg/contactUs.svg";
import { ReactComponent as EditTwoSvg } from "./svg/editTwo.svg";
import { ReactComponent as DeleteSvg } from "./svg/delete.svg";
import { ReactComponent as MoveFileSvg } from "./svg/moveFile.svg";
import { ReactComponent as ArrowDownSvg } from "./svg/arrowDown.svg";
import { ReactComponent as SearchSvg } from "./svg/search.svg";
import { ReactComponent as ArrowLeftSvg } from "./svg/arrow-Left.svg";
import { ReactComponent as LeaveSvg } from "./svg/leave.svg";
import { ReactComponent as SettingSvg } from "./svg/setting.svg";
import { ReactComponent as CloseSvg } from "./svg/close.svg";
import { ReactComponent as InviteSvg } from "./svg/invite.svg";
import { ReactComponent as UserSvg } from "./svg/user.svg";
import { ReactComponent as PointsSvg } from "./svg/points.svg";
import { ReactComponent as CheckSvg } from "./svg/checkMark.svg";
import { ReactComponent as LeftArrowSvg } from "./svg/leftArrow.svg";
import { ReactComponent as CheveronRightSvg } from "./svg/cheveron-right.svg";
import { ReactComponent as YesSvg } from "./svg/yes.svg";
import { ReactComponent as NoSvg } from "./svg/no.svg";
import { ReactComponent as UserLogo } from "./svg/user.svg";
import { ReactComponent as SmallUserSvg } from "./svg/smallUser.svg";
import { ReactComponent as LinkSvg } from "./svg/link.svg";
import { ReactComponent as ExpireSvg } from "./svg/expired.svg";
import { ReactComponent as CancelSvg } from "./svg/cancel.svg";
import { ReactComponent as CopiedSvg } from "./svg/copied.svg";
import { ReactComponent as LoginCircleSvg } from "./svg/login-circle.svg";
import { ReactComponent as UnboardLogoSvg } from "./svg/logo.svg";
import { ReactComponent as DoThisLater } from "./svg/do-this-later.svg";
import { ReactComponent as Drawer } from "./svg/drawer.svg";
import { ReactComponent as Eye } from "./svg/eye.svg";

export const Icons = {
  user: require("./icons/user.png"),
  plus: require("./icons/plus.png"),
  chat: require("./icons/chat.png"),
  chatGpt: require("./icons/chat-gpt.jpg"),
  clone: require("./icons/clone.png"),
  people: require("./icons/people.png"),
  cross: require("./icons/cross.png"),
  send: require("./icons/send.png"),
  copy: require("./icons/copy.png"),
  logout: require("./icons/logout.png"),
  chatFill: require("./icons/chat-fill.png"),
  programmingArrow: require("./icons/programming-arrow.png"),
  drawer: require("./icons/menu.png"),
  goback: require("./icons/undo.png"),
  workspaces: require("./icons/workspaces.png"),
};
export const Images = {
  userPlaceholder: require("./images/user.png"),
  profile: require("./images/profile.png"),
  loader: require("./images/loader.gif"),
  bannerSmaller: require("./images/banner-smaller-screen.png"),
  banner: require("./images/banner.png"),
  bannerLoader: require("./images/banner-loader.png"),
  bannerLoaderSmaller: require("./images/banner-loader-small.png"),
  LandingPageBackground: require("./images/lp-bg.png"),
  LandingPageBackgroundSmaller: require("./images/lp-bg-smaller.png"),
  LandingPageHeart: require("./images/lp-heart.png"),
  AttatchLp: require("./images/attatch-lp.png"),
  SendLp: require("./images/send-lp.png"),
  OptionsLp: require("./images/options-lp.png"),
  CloseLp: require("./images/close.png"),
};
export {
  AvatarSvg,
  EditSvg,
  BackArrowSvg,
  ProfileSvg,
  LogoSvg,
  DividerSvg,
  ArrowRightSvg,
  AddChatSvg,
  TopNavLineSvg,
  UserInvitationSvg,
  MenuSvg,
  DotMenuSvg,
  ContactUsSvg,
  EditTwoSvg,
  DeleteSvg,
  MoveFileSvg,
  ArrowDownSvg,
  SearchSvg,
  ArrowLeftSvg,
  LeaveSvg,
  SettingSvg,
  CloseSvg,
  InviteSvg,
  EditThreeSvg,
  UserSvg,
  PointsSvg,
  CheckSvg,
  LeftArrowSvg,
  CheveronRightSvg,
  YesSvg,
  NoSvg,
  UserLogo,
  SmallUserSvg,
  LinkSvg,
  ExpireSvg,
  CancelSvg,
  CopiedSvg,
  LoginCircleSvg,
  UnboardLogoSvg,
  DoThisLater,
  Drawer,
  Eye,
};
