import moment from "moment";
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { onValue, realDb, realTimeRef, push, set } from "../libraries/firebase";

export const chatApiSlice = createApi({
  baseQuery: fakeBaseQuery(),
  tagTypes: ["Conversation"],
  endpoints: (builder) => ({
    getConversation: builder.query({
      queryFn: () => ({ data: [] }),
      async onCacheEntryAdded(
        { id, currentThreadId },
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        const docRef = realTimeRef(realDb, `AIChats/${id}/${currentThreadId}`);
        let unsubscribe;
        try {
          await cacheDataLoaded;

          unsubscribe = onValue(docRef, (snapshot) => {
            if (snapshot.exists()) {
              const data = snapshot.val();
              updateCachedData(() =>
                Object.entries(data).map(([key, value]) => value)
              );
            } else {
              updateCachedData(() => []);
            }
          });
        } catch (error) {
          console.error("Error in onCacheEntryAdded:", error);
        }
        await cacheEntryRemoved;
        unsubscribe && unsubscribe();
      },
    }),
    addMessage: builder.mutation({
      queryFn: async ({
        id,
        currentThreadId,
        request,
        requesterId,
        responserId,
      }) => {
        const docRef = realTimeRef(realDb, `AIChats/${id}/${currentThreadId}`);
        const newMessageRef = push(docRef);
        await set(newMessageRef, {
          messageId: "",
          parentMessageId: "",
          request,
          requesterId,
          response: "...",
          responserId,
          timestamp: moment().unix(),
        });
        return { data: null };
      },
      invalidatesTags: ["Conversation"],
    }),
  }),
});

export const { useGetConversationQuery, useAddMessageMutation } = chatApiSlice;
