import { initializeApp } from "firebase/app";
import {
  getDatabase,
  set,
  ref as realTimeRef,
  push,
  update,
  remove,
  onDisconnect,
  onValue,
  get,
  child,
} from "firebase/database";
import { ref as storageRef, getStorage } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  getFirestore,
  collection,
  doc,
  addDoc,
  setDoc,
  updateDoc,
  getDoc,
  getDocs,
  orderBy,
  where,
  query,
  limit,
  Timestamp,
  writeBatch,
  GeoPoint,
  onSnapshot,
  collectionGroup,
  deleteDoc,
  startAfter,
  endBefore,
  limitToLast,
  endAt,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";

import { firebaseConfig } from "../../config";

const app = initializeApp(firebaseConfig);
const realDb = getDatabase(app);
const firestoreDb = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);
const createSubThread = httpsCallable(functions, "createSubThread");
const sendEmail = httpsCallable(functions, "sendEmail");
const payment = httpsCallable(functions, "payment");
const createPortalLink = httpsCallable(
  functions,
  "ext-firestore-stripe-payments-createPortalLink"
);
const changeTeamMembersRole = httpsCallable(functions, "changeTeamMembersRole");
const deleteTeamMember = httpsCallable(functions, "deleteTeamMember");

const handleInvitation = httpsCallable(functions, "handleInvitation");
const moveWorkspace = httpsCallable(functions, "moveWorkspace");
const leaveTeam = httpsCallable(functions, "leaveTeam");

const auth = getAuth(app);

export {
  realDb,
  realTimeRef,
  set,
  push,
  update,
  remove,
  onDisconnect,
  get,
  child,
  storageRef,
  storage,
  onValue,
  createSubThread,
  sendEmail,
  payment,
  firestoreDb,
  collection,
  doc,
  addDoc,
  setDoc,
  updateDoc,
  getDoc,
  getDocs,
  orderBy,
  where,
  query,
  limit,
  Timestamp,
  writeBatch,
  GeoPoint,
  onSnapshot,
  collectionGroup,
  deleteDoc,
  startAfter,
  endBefore,
  limitToLast,
  endAt,
  auth,
  app,
  createPortalLink,
  changeTeamMembersRole,
  handleInvitation,
  moveWorkspace,
  deleteTeamMember,
  leaveTeam,
};
